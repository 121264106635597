import Service from "../Service";

const resource = "mktpaneles/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });

    },

    list(obj, requestID) {
        return Service.post(resource + "listAll", obj, {
            params: { requestID: requestID },
        });

    },

    byID(obj, requestID) {
        return Service.post(resource + "byID", obj, {
            params: { requestID: requestID },
        });

    },

    savecomments(obj, requestID) {
        return Service.post(resource + "savecomments", obj, {
            params: { requestID: requestID },
        });

    },

    listcomments(obj, requestID) {
        return Service.post(resource + "listcomments", obj, {
            params: { requestID: requestID },
        });

    },

};
<template>
<v-col>

    <s-crud add edit height="auto" :filter="filter" remove @doubleClick="dobleClick" title="Paneles Publiciatarios" excel @addEvent="add()" @save=save($event) :config="config" @rowSelected="rowSelected($event)">
        <template v-slot:filter>
            <!-- <v-col>
                <v-row justify="center">
                    <v-col lg="3">
                        <s-select-definition clearable v-model="filter.TypeArea" :def="1147" label="Tipo de Área"> </s-select-definition>
                    </v-col>
                </v-row>
            </v-col> -->
        </template>
        <template scope="props">
            <v-col>
                <v-row justify="center">
                    <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%">
                        <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                            <h5>Datos Generales.</h5>
                        </v-card-title>
                    </v-card>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-select-definition :def="1211" label="Producto" v-model="props.item.PnlProduct"></s-select-definition>
                    </v-col>
                    <v-col lg="9" md="9" cols="6" class="s-col-text">
                        <s-text label="Nombre de la Empresa" v-model="props.item.PnlNameBusiness"></s-text>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-select-definition :def="1212" label="Tipo Producto" v-model="props.item.TypeProduct"></s-select-definition>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-select-definition :def="1210" label="Tipo alquiler" v-model="props.item.TypeRent"></s-select-definition>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-text decimal label="Precio Mensual + IGV" v-model="props.item.PnlPrice"></s-text>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text" style="margin-top:-10px">
                        <v-switch hide-details label="Contrato" v-model="props.item.PnlContract"></v-switch>
                    </v-col>

                    <v-col lg="3" md="3" cols="6" class="s-col-text" v-if="props.item.PnlContract">
                        <s-date label="Fecha Inicio" clearable v-model="props.item.PnlDateBegin"></s-date>
                    </v-col>

                    <v-col lg="3" md="3" cols="6" class="s-col-text" v-if="props.item.PnlContract">
                        <s-date label="Fecha Fin" clearable v-model="props.item.PnlDateEnd"></s-date>
                    </v-col>

                    <v-col lg="3" md="3" cols="6" class="s-col-text" style="margin-top:-10px">
                        <v-switch hide-details label="Licencia Municipal" v-model="props.item.PnlLicense"></v-switch>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text" style="margin-top:-10px">
                        <v-switch hide-details label="Contiene Iluminacion" v-model="props.item.PnlIluminacion"></v-switch>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-select-definition :def="1213" label="Estado" v-model="props.item.PnlStatus"></s-select-definition>
                    </v-col>

                    <v-col cols="12" class="s-col-text">
                        <s-textarea label="Medidas (Metros)" v-model="props.item.PnlMedidas"></s-textarea>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-text label="Caras" v-model="props.item.PnlCaras"></s-text>
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-select-definition label="Tipo Zona" :def="1214" v-model="props.item.TypeZone"></s-select-definition>
                    </v-col>

                </v-row>
                <v-row justify="center">
                    <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%" class="mt-3">
                        <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                            <h5>Datos Proveedor.</h5>
                        </v-card-title>
                    </v-card>
                    <v-col lg="3" md="3" cols="6" class="s-col-form">
                        <v-supplier v-model="Supplier" :value="Supplier" />
                    </v-col>
                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-text style="font-family: " label="Contacto" v-model="Supplier.ConName" ref="ConName"></s-text>
                    </v-col>

                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-text style="font-family: " number label="Telefono" v-model="Supplier.ConTelephone" ref="ConTelephone"></s-text>
                    </v-col>

                    <v-col lg="3" md="3" cols="6" class="s-col-text">
                        <s-text style="font-family: " label="Email" ref="ConEmail" v-model="Supplier.ConEmail"></s-text>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%" class="mt-3">
                        <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                            <h5>Datos Geofraficos.</h5>
                        </v-card-title>
                    </v-card>
                    <v-col lg="4" md="4" cols="4" class="s-col-form">
                        <s-text label="Latitud" v-model="props.item.PnlLatitude"></s-text>
                    </v-col>
                    <v-col lg="4" md="4" cols="4" class="s-col-form">
                        <s-text label="Longitud" v-model="props.item.PnlLongitude"></s-text>
                    </v-col>

                    <v-col lg="4" md="4" cols="4" class="s-col-form">
                        <s-text label="Otros Datos" v-model="props.item.PnlOtherData"></s-text>
                    </v-col>
                </v-row>
                <v-row justify="center" class="">
                    <v-card elevation="0" style="border-bottom: inset; border-top: outset" width="100%" class="mt-3">
                        <v-card-title style="margin-top: -10px; margin-bottom: -15px">
                            <h5>Datos de Localizaciòn.</h5>
                        </v-card-title>
                    </v-card>
                    <v-col cols="12" class="s-col-form">
                        <s-textarea label="Direcciòn" v-model="props.item.PnlAddress"></s-textarea>

                    </v-col>
                    <v-col cols="6" class="s-col-text">
                        <v-autocomplete clearable :items="itemsUbigee" item-value="GenID" item-text="GenDescription" label="Ubigeo" v-model="props.item.PnlUbigee">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="6" class="s-col-text">
                        <s-text label="Referencia" v-model="props.item.PnlReference"></s-text>
                    </v-col>
                </v-row>
            </v-col>
        </template>
    </s-crud>
</v-col>
</template>

<script>
import _sPanel from "@/services/Marketing/MktPanelPubli.js";
import _sSupplier from "@/services/Logistics/SupplierService";
import VSupplier from '../../Logistics/vSupplier.vue';

export default {
    name: "",
    components: {
        VSupplier
    },
    props: {},

    data: () => ({
        filter: {},

        itemsUbigee: [],
        Supplier: {
            ProviderRUC: ""
        },

        config: {
            model: {
                PnlID: "ID",
                PnlDateBegin: "date",
                PnlDateEnd: "date"
            },
            service: _sPanel,
            headers: [{
                    text: "ID",
                    value: "PnlID",
                    sortable: false,
                    width: 10
                },

                {
                    text: "Empresa",
                    value: "PnlNameBusiness",
                    sortable: false,
                },

                {
                    text: "Producto",
                    value: "PnlProductName",
                    sortable: false,
                },
                {
                    text: "Tipo Producto",
                    value: "TypeProductName",
                    sortable: false,
                },

                {
                    text: "Tipo Alq.",
                    value: "TypeRentName",
                    sortable: false,
                },

                {
                    text: "Ruc",
                    value: "SupRuc",
                    sortable: false,
                },
                {
                    text: "Proveedor",
                    value: "ProviderName",
                    sortable: false,
                },
                {
                    text: "Precio",
                    value: "PnlPrice",
                    sortable: false,
                },
                {
                    text: "Fec. Inicio",
                    value: "PnlDateBegin",
                    sortable: false,
                },
                {
                    text: "Fec. Fin",
                    value: "PnlDateEnd",
                    sortable: false,
                },
                {
                    text: "Latitud",
                    value: "PnlLatitude",
                    sortable: false,
                },
                {
                    text: "Longitud",
                    value: "PnlLongitude",
                    sortable: false,
                },
                {
                    text: "Direcciòn",
                    value: "PnlAddress",
                    sortable: false,
                },
                {
                    text: "Estado",
                    value: "SecStatus",
                    sortable: false,
                },

            ],
        },
    }),
    methods: {
        rowSelected(row) {
            console.log(row)
            if (row !== null) {
                if (row.length > 0) {
                    let sup = {
                        SupID: row[0].SupID,
                        SupName: row[0].ProviderName,
                    };

                    this.Supplier = sup

                    this.Supplier.ProviderRUC = row[0].SupRuc
                    this.Supplier.ConName = row[0].SupConName
                    this.Supplier.ConEmail = row[0].SupEmail
                    this.Supplier.ConTelephone = row[0].SupTelephone
                }
            }

        },

        add() {
            this.Supplier = {}
        },
        save(item) {

            console.log("supplier", this.Supplier)

            /* if (this.Supplier == undefined || this.Supplier == null || this.Supplier.ProviderRUC.length == 0) {
                return
            } */

            if(!item.PnlContract){
                item.PnlDateBegin = null
                item.PnlDateEnd = null
            }

            item.SupRuc = this.Supplier.ProviderRUC
            item.SupConName = this.Supplier.ConName
            item.SupEmail = this.Supplier.ConEmail
            item.SupTelephone = this.Supplier.ConTelephone

            item.UsrCreateID = this.$fun.getUserID()

            console.log("save", item)

            item.save();
        },
        dobleClick(item) {
            this.$emit("dblClick", item);
        },
    },
    created() {

        _sSupplier.listUbigeo("", this.$fun.getUserID()).then(resp => {
            this.itemsUbigee = resp.data

        })

    },

    watch: {
        /* Supplier() {
            if (this.Supplier != null)
                if (this.Supplier.SupID != null) {
                    this.SupID = this.Supplier.SupID;
                    this.ConName = this.Supplier.ConName;
                    this.ConTelephone = this.Supplier.ConTelephone;
                    this.ConEmail = this.Supplier.ConEmail;
                    this.ProviderBloqueado = this.Supplier.ProviderBloqueado;
                }
        }, */
    }
};
</script>
